import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { ReportDto } from "components/viewProgram/reports/view/report";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewEngagementReportCharts from "./engagementReport";
import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function ViewEngagementReport({ currentReportId }: any) {

    const { getAccessTokenSilently } = useAuth0();
    const [report, setReport] = useState(null as ReportDto | null);
    const [loading, setLoading] = useState(true);
    const { programId } = useParams();

    const fetchData = async (reportId: string) => {
        if (reportId !== null && reportId != undefined) {
            setLoading(true);
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/engagement/` + reportId);
            if (data.startDateUtc === undefined) {
                data.startDateUtc = dayjs();
            }
            setReport(data);
            setLoading(false);
            if (data.status !== "Complete" && data.status.toLowerCase().indexOf("error") === -1) {
                setTimeout(async () => { await fetchData(currentReportId), 5000 })
            }
        }

    };

    useEffect(() => {
        if (currentReportId !== null) {
            setLoading(true);
            // call the function
            fetchData(currentReportId)
                // make sure to catch any error
                .catch(console.error);
        }else {
            setReport(null);
            setLoading(false);
        }

    }, [getAccessTokenSilently, currentReportId]);

    const syncing = () => {
        return report?.status !== "Complete";
    }

    const isError = (status: string) => {
        return (status || "").toLowerCase().indexOf("error") > -1;
    }

    return (
        <div>
            {!loading && !report && (
            <Container sx={{ marginBottom: "25px" }}>
               <Box 
                   sx={{ 
                       display: 'flex', 
                       flexDirection: 'column',
                       alignItems: 'center',
                       textAlign: 'center'
                   }}
               >
                   <br />
                   <Typography variant="h6">
                       Upload your first report on the right!
                   </Typography>
                   <br />
                   {/* Placeholder Pie Chart */}
                   <Box 
                       sx={{ 
                           width: '400px', 
                           height: '300px', 
                           opacity: 0.3,
                           filter: 'grayscale(100%)'
                       }}
                   >
                       <HighchartsReact
                           highcharts={Highcharts}
                           options={{
                               chart: {
                                   type: 'pie',
                                   backgroundColor: 'transparent'
                               },
                               title: {
                                   text: 'Engagement Report'
                               },
                               series: [{
                                   name: 'Sample',
                                   data: [
                                        ['Not Started Yet', 45],
                                        ['In Progress', 25],
                                        ['Completed', 30]
                                   ]
                               }],
                               plotOptions: {
                                   pie: {
                                       colors: ['#d3d3d3', '#a9a9a9', '#808080'],
                                       dataLabels: {
                                           enabled: true,
                                           format: '{point.name}: {point.percentage:.1f}%'
                                       }
                                   }
                               },
                               credits: {
                                   enabled: false
                               }
                           }}
                       />
                   </Box>
               </Box>
            </Container>
            )}
            {report && syncing() &&
                <Grid item xs={12} sx={{
                    marginTop: "calc(5% + 60px)",
                    bottom: '50%',
                    width: "100%",
                }}>
                    <>
                        <Container>
                            {isError(report.status) &&
                                <>
                                    <br />
                                    <h4>We&apos;ve ran into an issue</h4>
                                    <Typography variant="subtitle2">Issue: {report.status}</Typography>
                                    <br />
                                    <br />
                                    <Typography variant="subtitle2">You can go back, click edit and upload your data again. <br /><br /> Quote report id : <b>{report.id}</b> to the team if you need more help.</Typography>
                                    <br />
                                    <br />

                                </>
                            }
                            {!isError(report.status) &&
                                <>
                                    <br />
                                    <br />
                                    <h4>Analysis in progress</h4>
                                    <LinearProgress color="secondary" />
                                    <br />
                                    <Typography variant="subtitle2">Current Status: {report.status}</Typography>
                                    <br />
                                    <Typography variant="subtitle2">This page will reload when analysis is complete</Typography>
                                    <br />
                                </>
                            }
                        </Container>
                    </>
                </Grid>
            }
            {!syncing() && <>
                <br />
                {loading &&
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                        <LinearProgress color="secondary" />
                        <br />
                        <br />
                        <br />
                    </Paper>
                } </>
            }
            {!syncing() && !loading &&
                <ViewEngagementReportCharts report={report} fetchData={fetchData} />
            }
        </div>
    )
}