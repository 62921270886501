import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Button, Stack, Tab, IconButton, Divider, styled, Snackbar } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BarChartIcon from '@mui/icons-material/BarChart';
import BreadCrumb from "@einhorn/breadcrumb/breadcrumb";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ReplyIcon from '@mui/icons-material/Reply';
import DataDrawer from "./view/dataDrawer";
import Title from "@einhorn/title";
import TabPanel from "@mui/lab/TabPanel";
import ViewReport from "./reports/view/view";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CreateProgram from "components/programs/create/create";
import GroupIcon from '@mui/icons-material/Group';
import ViewEngagementReport from "./engagement/view/view";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ViewProgramSummary from "./reports/view/summary/programSummary";

const drawerWidth = 330;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    position: 'relative',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
            },
        },
    ],
}));


export default function ViewProgram() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { getAccessTokenSilently } = useAuth0();
    const [program, setProgram] = useState({} as any);
    const [currentReport, setCurrentReport] = useState(searchParams.get("reportId"));
    const [currentEngagementReport, setCurrentEngagementReport] = useState(searchParams.get("engagementId"));

    const [loading, setLoading] = useState(true);
    const [dataDrawerOpen, setDataDrawerOpen] = useState(true);
    const [programsDrawerOpen, setProgramsDrawerOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState("reports");
    const { programId } = useParams();
    const [copyOpen, setCopyOpen] = useState(false);
    const navigate = useNavigate();

    const loadProgram = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId);
        setProgram(data);
        setLoading(false);
    }

    const loadReport = (reportId: string, setTab: boolean, type: string) => {
        if (type == "reports") {
            setCurrentReport(reportId)
            if(setTab){
                setCurrentTab("reports");
            }
        }

        if (type == "engagement") {
            setCurrentEngagementReport(reportId)
            if(setTab){
                setCurrentTab("engagement");
            }
        }
    }

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            loadProgram()
        };

        if (searchParams.get("reportId") != undefined) {
            loadReport(searchParams.get("reportId") || "", true, "reports")
        }

        if (searchParams.get("engagementId") != undefined) {
            loadReport(searchParams.get("engagementId") || "", true, "engagement")
        }

        setLoading(true);
        fetchData()
            .catch(console.error);
    }, []);

    const createNew = (programId?: string) => {
        navigate("/programs/" + programId + "/reports/create");
    };

    const viewPrograms = () => {
        navigate("/programs/");
    };

    const IsAdmin = () => {
        return localStorage.getItem("x-fathom-currentRole") == "Admin"
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const toggleDataDrawer = () => {
        setDataDrawerOpen(!dataDrawerOpen)
    }

    const share = async () => {
        let base = window.location.href;
        var index = base.indexOf("?reportId=");
        let copy = "";
        if (currentTab == "reports") {
            copy = base.slice(0, (index < 0 ? base.length : index)) + "?reportId=" + currentReport;
        } else {
            copy = base.slice(0, (index < 0 ? base.length : index));
        }

        await navigator.clipboard.writeText(copy);
        setCopyOpen(true)

    }


    return (
        <>
            <Snackbar
                message="Copied to clipboard"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={() => setCopyOpen(false)}
                open={copyOpen}
            />
            <Main open={dataDrawerOpen}>
                <Container maxWidth={false}>
                    <Grid container>
                        <Grid item xs={11}>
                            <BreadCrumb buttonFunc={viewPrograms} buttonText={"Programs"} pageText={program.name} />
                        </Grid>
                        <Grid item xs={1}>
                            <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '30px' }}>
                                <IconButton aria-label="open" onClick={() => toggleDataDrawer()}>
                                    <SpaceDashboardIcon  />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Title>{program.name} &nbsp;&nbsp;
                                <IconButton aria-label="open" onClick={() => setProgramsDrawerOpen(true)}>
                                    <MoreHorizIcon  />
                                </IconButton></Title>
                        </Grid>
                        <Grid item xs={1}>
                            <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '10px' }} >
                                <Button onClick={share} color="primary" variant="outlined" disableElevation startIcon={<ReplyIcon sx={{ transform: "scaleX(-1)" }} />} >Share</Button>
                            </Stack>
                        </Grid>
                    </Grid>

                    <TabContext value={currentTab} >
                        <TabList
                            onChange={handleChange}
                            aria-label="Module info"
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                            sx={{ '& .MuiTab-root.Mui-selected': { 
                                color: 'action.selected',

                             }, ml: "-15px", mt: "-15px" }}
                        >
                            <Tab key={1} icon={<BarChartIcon sx={{ transform: "rotate(90deg)" }} />} iconPosition="start" label="Reports" value={"reports"} sx={{ textTransform: 'none' }} />
                            <Tab key={2} icon={<GroupIcon />} iconPosition="start" label="Engagement" value={"engagement"} sx={{ textTransform: 'none' }} />
                            <Tab key={3} icon={<EmojiPeopleIcon />} iconPosition="start" label="Summary" value={"summary"} sx={{ textTransform: 'none' }} />

                        </TabList>
                        <Divider sx={{ mt: '-10px' }} />

                        <TabPanel key={1} value={"reports"}>
                            <ViewReport currentReportId={currentReport} />
                        </TabPanel>
                        <TabPanel key={2} value={"engagement"}>
                            <ViewEngagementReport currentReportId={currentEngagementReport} />
                        </TabPanel>
                        <TabPanel key={3} value={"summary"}>
                            <ViewProgramSummary programId={programId} />
                        </TabPanel>
                    </TabContext>
                </Container>
            </Main>
            <DataDrawer
                drawerOpen={dataDrawerOpen}
                toggleDrawer={toggleDataDrawer}
                width={drawerWidth}
                programId={programId}
                currentReportId={currentReport}
                currentEngagementReportId={currentEngagementReport}
                setFirstReport={loadReport}
                currentTab={currentTab} />

            <CreateProgram
                drawerOpen={programsDrawerOpen}
                toggleDrawer={() => setProgramsDrawerOpen(!programsDrawerOpen)}
                editProgramId={programId}
            />
        </>
    );
}
