import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Box, DialogTitle, Typography, IconButton, Grid, TextField, CircularProgress, Alert, List, Tooltip, Checkbox, Button, Chip, FormControl, Input, InputLabel, MenuItem, Select, FormLabel, RadioGroup, FormControlLabel, SelectChangeEvent, Container, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FathomClient from "@api/fathomapi";
import { InsightsChartType, ProgramReportQuestions, ReportQuestion, ReportQuestionId } from "components/insights/insightTypes";
import NpsInsightsChart from "components/insights/charts/npsInsightsChart";
import OpinionInsightsChart from "components/insights/charts/opinionInsightsChart";
import RatingInsightsChart from "components/insights/charts/ratingInsightsChart";
import LoadingChart from "../charts/loadingChart";
import OpinionSummaryInsightsChart from "components/insights/charts/opinionSummaryInsightsChart";
import { useNavigate } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

  
const allowChartTypes = {
    [InsightsChartType.OpinionSummary]: [InsightsChartType.Opinion],
    [InsightsChartType.Opinion]: [InsightsChartType.Opinion, InsightsChartType.OpinionSummary],
    [InsightsChartType.Rating]: [InsightsChartType.Rating],
    [InsightsChartType.Nps]: [InsightsChartType.Nps],
    [InsightsChartType.Open]: [] as InsightsChartType[],
    [InsightsChartType.Multi]: [] as InsightsChartType[],
    [InsightsChartType.Ranking]: [] as InsightsChartType[],
    [InsightsChartType.Unmapped]: [] as InsightsChartType[],
}


export default function EditInsightChart({ dataPointId, programId, open, setModalOpen, order, fetchData }: any) {

    const [loading, setLoading] = useState(true); // Add a loading state
    const [selectedReports, setSelectedReports] = useState<any[]>([]);
    const [optionsLoaded, setOptionsLoaded] = useState(false);

    const [chartDataPoint, setChartDataPoint] = useState({
        title: "",
        order: order,
        type: "Multi",
        filter: {
            reportIdFilter: [],
            questionIdFilter: [],
        },
        id: undefined
    } as any);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const [chartQueryData, setChartQueryData] = useState({
        categories: [],
        series: new Map<string, number[]>()
    } as any);


    const [reportQuestionMap, setReportQuestionMap] = useState(new Map<string, ReportQuestionId[]>());

    const [chartOptions, setChartOptions] = useState({
        programId: programId,
        reports: [],
    } as ProgramReportQuestions);

    const [validChartOptions, setValidChartOptions] = useState({
        programId: programId,
        reports: [],
    } as ProgramReportQuestions);

    const { getAccessTokenSilently } = useAuth0();
    const canSave = () => {
        return chartDataPoint.title !== "" && chartDataPoint.filter.reportIdFilter.length > 0;
    }
    
    const getOptions = async () => {
        setOptionsLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const response = await apiClient.get(`{clientId}/programs/${programId}/insights/options`);
            setChartOptions(response.data);
    
            setValidChartOptions({ ...validChartOptions, reports: response.data.reports });
            setOptionsLoaded(true); // Mark options as loaded
        } catch (error) {
            console.error('Error loading options:', error);
        } finally {
            setOptionsLoading(false);
        }
    }

    const preLoadInsight = async () => {
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const response = await apiClient.get(`{clientId}/programs/${programId}/insights/chart/${dataPointId}`);
            
            console.log('Loading chart:', response.data);
            
            // Ensure filter exists before accessing its properties
            const filter = response.data.filter || {
                reportIdFilter: [],
                questionIdFilter: [],
                type: chartDataPoint.type // Use current type as fallback
            };
            
            const chartType = filter.type as keyof typeof allowChartTypes;
            
            // Set chart data point with safe defaults
            setChartDataPoint({
                title: response.data.title || '',
                order: order,
                id: response.data.dataPointId || '',
                type: chartType,
                filter: {
                    reportIdFilter: filter.reportIdFilter || [],
                    questionIdFilter: filter.questionIdFilter || [],
                    type: chartType
                },
            });
    
            // Only proceed with validChartOptions if chartOptions is loaded
            if (chartOptions?.reports) {
                const validReports = chartOptions.reports.filter(y => 
                    y.questionIds.some(z => allowChartTypes[chartType].includes(z.type))
                );
                setValidChartOptions({ ...validChartOptions, reports: validReports });
    
                // Get matching reports
                const matchingReports = chartOptions.reports.filter(report => 
                    filter.reportIdFilter?.includes(report.reportId)
                );
                
                console.log('ChartOptions:', chartOptions);
                console.log('Matching reports:', matchingReports);
                setSelectedReports(matchingReports);
    
                // Initialize question map for selected reports
                const initialQuestionMap = new Map<string, any[]>();
                matchingReports.forEach(report => {
                    const reportQuestions = report.questionIds.filter(question => 
                        filter.questionIdFilter?.includes(question.questionId)
                    );
                    if (reportQuestions.length > 0) {
                        initialQuestionMap.set(report.reportId, reportQuestions);
                    }
                });
                setReportQuestionMap(initialQuestionMap);
            }
    
            // Get the chart data if we have filter values
            if (filter.reportIdFilter?.length && filter.questionIdFilter?.length) {
                const dataResponse = await apiClient.post(`{clientId}/programs/${programId}/insights/data`, {
                    reportIdFilter: filter.reportIdFilter,
                    questionIdFilter: filter.questionIdFilter,
                    type: chartType
                });
                
                setChartQueryData({
                    data: dataResponse.data
                });
            }
    
        } catch (error) {
            console.error('Error loading insight:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (chartDataPoint?.filter?.reportIdFilter?.length > 0 && 
            chartDataPoint?.filter?.questionIdFilter?.length > 0 &&
            !dataLoading) { 
            getData();
        }
    }, [chartDataPoint.filter.questionIdFilter, chartDataPoint.type]);
    
    const getData = async () => {
        setDataLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            
            // Format the filter correctly
            const filter = {
                reportIdFilter: chartDataPoint.filter.reportIdFilter,
                questionIdFilter: chartDataPoint.filter.questionIdFilter.map((q :any )=> 
                    typeof q === 'string' ? q : q.questionId
                ),
                type: chartDataPoint.type
            };
    
            const response = await apiClient.post(`{clientId}/programs/${programId}/insights/data`, filter);
            setChartQueryData({
                data: response.data
            });
        } catch (error) {
            console.error('Error fetching chart data:', error);
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            if (open && dataPointId) {
                await getOptions();
            }
        };
        loadData();
    }, [open, dataPointId]);

    useEffect(() => {
        const loadInsight = async () => {
            if (optionsLoaded && open && dataPointId) {
                await preLoadInsight();
            }
        };
        loadInsight();
    }, [optionsLoaded, open, dataPointId]);

    const clearModal = () => {
        setModalOpen(false);
        setOptionsLoading(false); 
        setLoading(false); 
        setChartOptions({
            programId: programId,
            reports: []
        })
        setValidChartOptions({
            programId: programId,
            reports: []
        })
        setChartQueryData({
            categories: [],
            series: new Map<string, number[]>
        })

        setChartDataPoint({
            title: "",
            order: order,
            type: "",
            filter: {
                reportIdFilter: [],
                questionIdFilter: []
            },
            id: undefined
        })

    };
    
    const handleReportChange = (event: SelectChangeEvent<string[]>) => {
        const selectedIds = Array.isArray(event.target.value) 
            ? event.target.value 
            : [event.target.value];
                
        const selectedReportObjects = validChartOptions.reports.filter(
            report => selectedIds.includes(report.reportId)
        );
        
        setSelectedReports(selectedReportObjects);
        
        const updatedQuestionMap = new Map(reportQuestionMap);
        Array.from(updatedQuestionMap.keys()).forEach(reportId => {
            if (!selectedIds.includes(reportId)) {
                updatedQuestionMap.delete(reportId);
            }
        });
        setReportQuestionMap(updatedQuestionMap);
    
        const maintainedQuestions = Array.from(updatedQuestionMap.values()).flat();
        setChartDataPoint({
            ...chartDataPoint,
            filter: {
                ...chartDataPoint.filter,
                reportIdFilter: selectedIds,
                questionIdFilter: maintainedQuestions.map(q => q.questionId) // Just send the IDs
            }
        });
    };

    const handleQuestionChange = (report: any, event: any) => {
        let mp = new Map(reportQuestionMap);
    
        if (event.target.value === "clear") {
            mp.delete(report.reportId);
        } else {
            mp.set(report.reportId, event.target.value);
        }
    
        let allQuestions = Array.from(mp.values()).flat();
        setReportQuestionMap(mp);
    
        if (allQuestions.length > 0) {
            setChartDataPoint({
                ...chartDataPoint,
                filter: {
                    ...chartDataPoint.filter,
                    questionIdFilter: allQuestions.map(q => q.questionId) 
                }
            });
        }
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        const newType = event.target.value as InsightsChartType;
    
        setChartDataPoint({
            ...chartDataPoint,
            type: newType,
            filter: {
                reportIdFilter: [],
                questionIdFilter: [],
            }
        });
    
        setSelectedReports([]); 
        setReportQuestionMap(new Map()); 
    
        let validReports = chartOptions.reports.filter(y => 
            y.questionIds.some(z => allowChartTypes[newType].includes(z.type))
        );
        setValidChartOptions({ ...validChartOptions, reports: validReports });
    };

   
    const confirmData = async () => {
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            
            // Match the C# InsightsDataPoint model exactly
            const dataToSend = {
                title: chartDataPoint.title.trim(),
                description: null,
                filter: {
                    reportIdFilter: chartDataPoint.filter.reportIdFilter,
                    questionIdFilter: chartDataPoint.filter.questionIdFilter,
                    responseFilter: [],
                    type: chartDataPoint.type
                }
            };
    
            console.log('Saving chart data:', dataToSend);
    
            const response = await apiClient.put(
                `{clientId}/programs/${programId}/insights/chart/${dataPointId}`, 
                dataToSend
            );
            
            console.log('Backend response:', response);
    
            if (typeof fetchData === 'function') {
                await fetchData(programId);
            }
            clearModal();
        } catch (error) {
            console.error('Error saving chart:', error);
        }
    };

    return (
        <Modal open={open} onClose={clearModal}>
            <Box className="modal-content">
            {loading ? ( // Show loading indicator or placeholder while loading
                <CircularProgress />
            ) : (
            <>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography variant="h6" component="h2">Edit Insight</Typography>
                            <Typography variant="subtitle1">Select questions from the reports below to compare</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={clearModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Box className="modal-body">
                    {!optionsLoading &&
                        <Grid container spacing={2}>
                            <Grid item container xs={6}   >
                                <div className="rounded-box top-box">
                                    <Grid container item xs={12} >
                                        <Grid item xs={3} ><span className="titleFieldRow">Title</span></Grid>
                                        <Grid item xs={9} >
                                            <FormControl fullWidth className="dataFilterField">
                                                <TextField
                                                    fullWidth
                                                    value={chartDataPoint?.title}
                                                    onChange={(e) => setChartDataPoint({ ...chartDataPoint, title: e.target.value })}
                                                    margin="normal"
                                                />
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                    <Grid item container xs={12} >

                                        <Grid item xs={3} ><span className="titleFieldRow">Chart type</span></Grid>
                                        <Grid item xs={9} >
                                            <FormControl fullWidth className="dataFilterField">
                                                <Select
                                                    labelId="type-filter"
                                                    fullWidth
                                                    className="selectBox"
                                                    id="type-filter-chip"
                                                    value={chartDataPoint.type}
                                                    onChange={handleTypeChange}
                                                    input={<Input id="select-multiple-chip" />}
                                                    disableUnderline
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value={InsightsChartType.Nps}>Nps</MenuItem>
                                                    <MenuItem value={InsightsChartType.Rating}>Rating</MenuItem>
                                                    <MenuItem value={InsightsChartType.Opinion}>Opinion</MenuItem>
                                                    <MenuItem value={InsightsChartType.OpinionSummary}>Opinion Summary</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item container xs={12}>
                                        <Grid item xs={3} ><span className="titleFieldRow">Reports</span></Grid>
                                        <Grid item xs={9} >
                                            <FormControl fullWidth className="dataFilterField">
                                            <Select
                                                labelId="question-filter"
                                                fullWidth
                                                className="selectBox"
                                                id="question-filter-chip"
                                                multiple
                                                value={selectedReports.map(r => r.reportId)} // Make sure we're using IDs
                                                onChange={handleReportChange}
                                                input={<Input id="select-multiple-chip" />}
                                                MenuProps={MenuProps}
                                                disableUnderline
                                                renderValue={(selected: string[]) => {
                                                    return selectedReports.length > 1 
                                                        ? `${selectedReports.length} selected` 
                                                        : selectedReports[0]?.title;
                                                }}
                                            >
                                                {validChartOptions.reports.map((report) => (
                                                    <MenuItem 
                                                        value={report.reportId}
                                                        key={report.reportId}
                                                    >
                                                        <Checkbox 
                                                            checked={selectedReports.some(r => r.reportId === report.reportId)}
                                                        />
                                                        <ListItemText primary={report.title} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                {selectedReports.length > 0 && (
                                    <Grid item xs={12} className="rounded-box bottom-box">
                                        {selectedReports.map((report: any) => (
                                            <Grid container item xs={12} key={report.reportId}>
                                                <Grid item xs={3}>
                                                    <span className="titleFieldRow">{report.title}</span>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Select
                                                        labelId="question-filter"
                                                        className="selectBox"
                                                        id="question-filter-chip"
                                                        value={reportQuestionMap.get(report.reportId) || []}
                                                        multiple
                                                        onChange={(event) => handleQuestionChange(report, event)}
                                                        input={<Input id="select-multiple-chip" />}
                                                        MenuProps={MenuProps}
                                                        disableUnderline
                                                        renderValue={(selected) => (
                                                            <>{selected.length > 1 ? `${selected.length} selected` : selected[0]?.title}</>
                                                        )}
                                                        fullWidth
                                                    >
                                                      {report.questionIds
                                                            .filter((y: any) => {
                                                                const type = chartDataPoint.type as keyof typeof allowChartTypes;
                                                                return type && (allowChartTypes[type] || []).includes(y.type);
                                                            })
                                                            .map((question: any) => (
                                                                <MenuItem value={question} key={question.questionId}>
                                                                    <Tooltip title={question.title.length > 45 ? question.title : ''}>
                                                                        <span>{question.title}</span>
                                                                    </Tooltip>
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        ))}
                                </Grid>
                                )}
                                {(!chartDataPoint?.filter?.reportIdFilter?.length) && <Grid item xs={12}></Grid>}
                            </Grid>
                            <Grid item  xs={6}>
                                <Container>
                                    <div className="rounded-box chartpreview-box">
                                        <Typography variant="subtitle1">Preview</Typography>
                                        <br />
                                        <div className="rounded-box">
                                            {
                                                chartQueryData?.data?.categories.length > 0 && chartDataPoint?.filter?.questionIdFilter.length > 0 &&
                                                <>
                                                    <Typography variant="h6" component="h2">&nbsp;&nbsp;&nbsp;{chartDataPoint.title}</Typography>   <br />
                                                    {
                                                        chartDataPoint.type == InsightsChartType.Nps &&
                                                        <NpsInsightsChart insight={chartQueryData} />
                                                    }
                                                    {
                                                        chartDataPoint.type == InsightsChartType.Opinion &&
                                                        <OpinionInsightsChart insight={chartQueryData} />
                                                    }
                                                    {
                                                        chartDataPoint.type == InsightsChartType.Rating &&
                                                        <RatingInsightsChart insight={chartQueryData} />
                                                    }
                                                    {
                                                        chartDataPoint.type == InsightsChartType.OpinionSummary &&
                                                        <OpinionSummaryInsightsChart insight={chartQueryData} />
                                                    }

                                                </>
                                            }
                                            {
                                                (chartQueryData?.data?.categories.length == 0 || chartDataPoint?.filter?.questionIdFilter.length == 0) &&
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <LoadingChart title="Data insight" />

                                                </Box>
                                            }
                                        </div>
                                    </div>
                                </Container>
                            </Grid>
                        </Grid>

                    }
                </Box>
                <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Grid item xs={1}>
                        <Tooltip title={canSave() ? '' : "Please set a title and select at least one report"}>
                            <span><Button variant="outlined" disabled={!canSave()} onClick={() => confirmData()}>Confirm </Button></span>
                        </Tooltip>
                    </Grid>

                </Box>
                </>
            )}
            </Box >
        </Modal >
    );
}