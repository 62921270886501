import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Button, Stack, IconButton, styled, Snackbar, Typography, Divider, Tab } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Title from "@einhorn/title";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

const drawerWidth = 330;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    position: 'relative',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
            },
        },
    ],
}));


export default function ViewDashboards() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { getAccessTokenSilently } = useAuth0();
    const [program, setProgram] = useState({} as any);
    const [currentReport, setCurrentReport] = useState(searchParams.get("reportId"));
    const [currentEngagementReport, setCurrentEngagementReport] = useState(searchParams.get("engagementId"));

    const [loading, setLoading] = useState(true);
    const [dataDrawerOpen, setDataDrawerOpen] = useState(true);
    const [programsDrawerOpen, setProgramsDrawerOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState("my");
    const { programId } = useParams();
    const [copyOpen, setCopyOpen] = useState(false);
    const navigate = useNavigate();

    const loadProgram = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
    }

    const loadReport = (reportId: string, setTab: boolean, type: string) => {
        if (type == "reports") {
            setCurrentReport(reportId)
            if(setTab){
                setCurrentTab("reports");
            }
        }

        if (type == "engagement") {
            setCurrentEngagementReport(reportId)
            if(setTab){
                setCurrentTab("engagement");
            }
        }
    }

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            loadProgram()
        };

        if (searchParams.get("reportId") != undefined) {
            loadReport(searchParams.get("reportId") || "", true, "reports")
        }

        if (searchParams.get("engagementId") != undefined) {
            loadReport(searchParams.get("engagementId") || "", true, "engagement")
        }

        setLoading(true);
        fetchData()
            .catch(console.error);
    }, []);

    const createNew = (programId?: string) => {
        navigate("/programs/" + programId + "/reports/create");
    };

    const viewPrograms = () => {
        navigate("/programs/");
    };

    const IsAdmin = () => {
        return localStorage.getItem("x-fathom-currentRole") == "Admin"
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const toggleDataDrawer = () => {
        setDataDrawerOpen(!dataDrawerOpen)
    }

    const share = async () => {
        let base = window.location.href;
        var index = base.indexOf("?reportId=");
        let copy = "";
        if (currentTab == "reports") {
            copy = base.slice(0, (index < 0 ? base.length : index)) + "?reportId=" + currentReport;
        } else {
            copy = base.slice(0, (index < 0 ? base.length : index));
        }

        await navigator.clipboard.writeText(copy);
        setCopyOpen(true)

    }


    return (
        <>
            <Snackbar
                message="Copied to clipboard"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={() => setCopyOpen(false)}
                open={copyOpen}
            />
            <Main open={dataDrawerOpen}>
                <Container >
                    <Grid container>
                        <Grid item xs={10}>
                            <Title>Boards</Title>
                        </Grid>
                        <Grid item xs={2}>
                            <SwapVertIcon /> &nbsp;&nbsp;
                            <SearchIcon />&nbsp;&nbsp;
                            <Button variant="contained" startIcon={<AddIcon />}>
                                Board
                                </Button>
                        </Grid>
                    </Grid>

                    <TabContext value={currentTab} >
                        <TabList
                            onChange={handleChange}
                            aria-label="Module info"
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                            sx={{ '& .MuiTab-root.Mui-selected': { 
                                color: 'action.selected',

                             }, ml: "-15px", mt: "-15px" }}
                        >
                            <Tab key={0} label="My boards" value={"my"} sx={{ textTransform: 'none' }} />
                            <Tab key={1} label="Shared" value={"shared"} sx={{ textTransform: 'none' }} />
                        </TabList>
                        <Divider sx={{ mt: '-10px' }} />
                        <TabPanel key={0} value={"my"}>
                        </TabPanel>
                        <TabPanel key={1} value={"shared"}>
                        </TabPanel>

                    </TabContext>

                </Container>
            </Main>
            
        </>
    );
}
