import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from 'react-router-dom';
import { ListItem } from '@mui/material';
import { useLocation } from 'react-router-dom';

const mainItems = [

  {
    text :"Programs",
    icon: BarChartIcon,
    to: "/programs"
  },
  {
    text :"Boards", 
    icon: DashboardIcon,
    to: "/boards/list"
  },
  {
    text :"Users",
    icon: PeopleIcon,
    comp: Link,
    to: "/users"
  }
]

const secondaryItems = [
  {
    text :"Billing",
    icon: AssignmentIcon,
    to: "/billing"
  }
]


export const MainListItems = (open: boolean) => {
  const location = useLocation();

  return mainItems.map((item) => {
    const itemPath = item.to.replace(/^\//, '');
    const currentPath = location.pathname.replace(/^\//, '');  
    const isSelected = currentPath.startsWith(itemPath);

        return (<ListItem key={item.text} sx={{
          display: 'block', 
          marginBottom:"-20px",
          marginLeft:"0px",
          mb: 1,
          '&:has(.Mui-selected)': {  
          backgroundColor: 'grey.300',
          borderRadius: '20px 0 0 20px',
        }}}>
          <ListItemButton component={Link} to={item.to}
            selected={isSelected}
            sx={{
              minHeight: 24,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              '& .routingIcon': {
                color: 'text.secondary',
              },
              '&.Mui-selected': {  
                backgroundColor: 'transparent', 
                borderRadius: '8px 0 0 8px',
                '& .routingIcon': {  
                  color: 'action.selected',
                },
              },
            }}>
            <ListItemIcon sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }} >
              <item.icon className='routingIcon' />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={item.text}  />
          </ListItemButton>
        </ListItem>)
      })};

export const SecondaryListItems = (open: boolean) => {
  const location = useLocation();

  return secondaryItems.map((item) => {
    const itemPath = item.to.replace(/^\//, '');
    const currentPath = location.pathname.replace(/^\//, '');  
    const isSelected = currentPath.startsWith(itemPath);

    return (<ListItem key={item.text} sx={{
      display: 'block', 
      marginBottom:"-20px",
      marginLeft:"0px",
      '&:has(.Mui-selected)': {  
      backgroundColor: 'grey.300',
      borderRadius: '20px 0 0 20px',
    }}}>
      <ListItemButton component={Link} to={item.to} 
        selected={isSelected}
        sx={{
          minHeight: 24,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          '& .routingIcon': {
            color: 'text.secondary',
          },
          '&.Mui-selected': {  
            backgroundColor: 'transparent', 
            borderRadius: '8px 0 0 8px',
            '& .routingIcon': {  
              color: 'action.selected',
            },
          },
        }}>
        <ListItemIcon sx={{
          minWidth: 0,
          mr: open ? 3 : 'auto',
          justifyContent: 'center',
        }} >
          <item.icon className='routingIcon' />
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={item.text}  />
      </ListItemButton>
    </ListItem>)
  })};