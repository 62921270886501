import { Container, Box, Grid, Typography, IconButton, CircularProgress, Button, Paper, Tooltip } from "@mui/material";
import NpsInsightsChart from "components/insights/charts/npsInsightsChart";
import OpinionInsightsChart from "components/insights/charts/opinionInsightsChart";
import RatingInsightsChart from "components/insights/charts/ratingInsightsChart";
import Highcharts from "highcharts";
import { useState } from "react";
import { InsightsChartsProps } from "components/insights/insightTypes";
import AddInsightChart from "../addChart/addChart";
import EditInsightChart from "../editChart/editChart";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OpinionSummaryInsightsChart from "components/insights/charts/opinionSummaryInsightsChart";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from "react";
import { Menu, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmDialog from "@einhorn/confirmDialog";
export default function ViewInsightsCharts({ insightsCharts, programId, fetchData }: InsightsChartsProps) {

    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editingDataPointId, setEditingDataPointId] = useState<string | null>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
        setEditMode(false);
        setEditingDataPointId(null);
    };
    const { getAccessTokenSilently } = useAuth0();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedInsight, setSelectedInsight] = useState<string | null>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, insightId: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedInsight(insightId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedInsight(null);
    };

    const handleDelete = async () => {

        const apiClient = new FathomClient(await getAccessTokenSilently());
        await apiClient.delete(`{clientId}/programs/${programId}/insights/chart/` + selectedInsight);
        fetchData();
        setConfirmDeleteOpen(false);
        handleMenuClose();
    };
    const handleEdit = () => {
        if (selectedInsight) {
            editAction(selectedInsight);
            handleMenuClose();
        }
    };

    const editAction = (editDataPointId: string) => {
        setEditingDataPointId(editDataPointId);
        setEditMode(true);
        setModalOpen(true);
    };
    return (
        <Container maxWidth={false} disableGutters>
            <Box maxWidth="xl" mx="auto">
                <Grid container>
                    <Grid item xs={2}>
                        <Typography variant="h6" sx={{ my: 4, ml: 2 }}>View Insights &nbsp;
                        <a onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }}>
                            <AddCircleIcon
                                sx={{
                                    my: 0,
                                    color: 'text.primary',
                                    transform: 'scale(0.8)',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.0)',
                                        color: 'action.selected',
                                    }
                                }}
                                fontSize="small"
                            />
                        </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {insightsCharts.map((insight) => (
                        <Grid item xs={6} key={insight.dataPointId}>
                            <Paper
                                className="engagement-box"
                                key={insight.title}
                            >
                                <Box key={insight.dataPointId} className="insight-box">
                                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
                                        <Typography variant="subtitle1">{insight.title}
                                            <div style={{ fontSize: 13 }}>{insight.description}</div>
                                        </Typography>
                                        <Typography variant="subtitle2">{insight.type[0].toUpperCase() + insight.type.slice(1).replace(/(?!^)([A-Z]|\d+)/g, " $1")}</Typography>

                                        <IconButton
                                            aria-label="more"
                                            onClick={(event) => handleMenuOpen(event, insight.dataPointId)}
                                        >
                                            <MoreHorizIcon fontSize="small" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <MenuItem onClick={handleEdit}>
                                                <EditIcon fontSize="small" style={{ marginRight: '8px' }} />
                                                <Typography variant="body2">Edit insight</Typography>
                                            </MenuItem>
                                            <MenuItem style={{ color: 'red' }} onClick={() => setConfirmDeleteOpen(true)} >
                                                <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} />
                                                <Typography variant="body2">Delete insight</Typography>
                                            </MenuItem>
                                        </Menu>

                                    </Box>
                                    <Box className="insight-chart-container">
                                        {insight.type.toLowerCase() == "rating" &&
                                            <RatingInsightsChart
                                                highcharts={Highcharts}
                                                insight={insight} />
                                        }
                                        {insight.type.toLowerCase() == "opinion" &&
                                            <OpinionInsightsChart
                                                highcharts={Highcharts}
                                                insight={insight} />
                                        }
                                        {insight.type.toLowerCase() == "nps" &&
                                            <NpsInsightsChart
                                                highcharts={Highcharts}
                                                insight={insight} />
                                        }
                                        {insight.type.toLowerCase() == "opinionsummary" &&
                                            <OpinionSummaryInsightsChart
                                                highcharts={Highcharts}
                                                insight={insight} />
                                        }

                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                    <AddInsightChart
                        fetchData={fetchData}
                        setModalOpen={setModalOpen}
                        programId={programId}
                        open={modalOpen}
                    />
                     <ConfirmDialog
                        title="Delete program"
                        content="Are you sure you want to delete this chart?"
                        open={confirmDeleteOpen}
                        setOpen={setConfirmDeleteOpen}
                        confirmAction={handleDelete}
                    />
                    {editMode &&
                        <EditInsightChart
                            dataPointId={editingDataPointId}
                            fetchData={fetchData}
                            setModalOpen={handleModalClose}
                            programId={programId}
                            open={modalOpen}
                        />}
                </Grid>
            </Box>
        </Container>
    );
}