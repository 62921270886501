import ProtectedRoute from "../auth/protected-route";
import { Route, Routes } from "react-router-dom";
import User from "./user/user";
import Programs from "./programs/programs";
import CreateProgram from "./programs/create/create";
import UsersList from "./users/users";
import ClientList from "./clientSelect/clientSelect";
import AcceptInvite from "./invite/invite";
import LoginPage from "./login/login";
import NotFound from "./NotFound"
import ViewProgram from "./viewProgram/viewProgram";
import ViewDashboards from "./dashboards/dashboards";

const Routing = () => {
  return (
    <Routes >
      <Route
        path="/"
        element={<ProtectedRoute component={User} />}
      />
      <Route
        path="/login"
        element={<LoginPage />}
      />
      <Route
        path="/programs"
        element={<ProtectedRoute component={Programs} />}
      />
      <Route
        path="/programs/create/:id?/:step?"
        element={<ProtectedRoute component={CreateProgram} />}
      />
      <Route
        path="/programs/:programId"
        element={<ProtectedRoute component={ViewProgram} />}
      />
      <Route
        path="/boards/list"
        element={<ProtectedRoute component={ViewDashboards} />}
      />
      <Route
        path="/users"
        element={<ProtectedRoute component={UsersList} />}
      />
      <Route
        path="/select"
        element={<ProtectedRoute component={ClientList} />}
      />
      <Route
        path="/invite"
        element={<ProtectedRoute component={AcceptInvite} />}
      />
      <Route
        path="/callback"
        element={<ProtectedRoute component={LoginPage} />} />
      <Route
          path="*"
          element={<NotFound />}
        />
    </Routes>
  )
}

export default Routing;
