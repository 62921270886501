import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight="100vh"
    >
      {location.pathname.includes('dashboard') ? (
        <Typography variant="h5" gutterBottom>
            Dashboard page is being developed. Check Programs instead!
        </Typography>
      ) : (
        <Typography variant="h5" gutterBottom>
          Seems like you are looking for something that does not exist...
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/programs')}
      >
        Go back to Programs
      </Button>
    </Box>
  );
};

export default NotFound;
