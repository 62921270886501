import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GroupIcon from '@mui/icons-material/GroupOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHighOutlined';
import Avatar from '@mui/material/Avatar';

import { ChangeEvent, useEffect, useRef, useState } from "react";
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import BarChartIcon from '@mui/icons-material/BarChart';
import BarChartIconOutlined from '@mui/icons-material/BarChartOutlined';

import BuildIcon from '@mui/icons-material/Build';
import Cookies from "universal-cookie";
import { PagedResult } from "app/types";
import debounce from 'lodash/debounce';
import CreateProgram from "./create/create";

const cookies = new Cookies();

const drawerWidth = 339;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'relative',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
            },
        },
    ],
}));


export default function Programs() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [programs, setPrograms] = useState({
    data: [],
    paging: {
      length: 0,
      pageSize: 0,
      page: 0,
      total: 0
    }
  } as PagedResult<any>);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [editProgramId, setEditProgramId] = useState("");
  const [loading, setLoading] = useState(true);
  const mapData = (z: any) => {
    return {
      id: z.id,
      name: z.name,
      description: z.description,
      startDate: z.startDate,
    };
  };

  const IsAdmin = () => {
    return localStorage.getItem("x-fathom-currentRole") == "Admin"
  }

  const createNew = () => {
    setDrawerOpen(true);
  };
  const edit = (id: string) => {
    setEditProgramId(id)
    setDrawerOpen(true);

  };

  const viewReports = (programId: string) => {
    navigate("/programs/" + programId );
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage != page) {
      setPage(page);
      loadPrograms(search, newPage)
    }
  };

  const handleSearch = debounce((searchTerm: string, pageNo: number) => {
    loadPrograms(searchTerm, pageNo)
  }, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue)
    handleChangePage({} as ChangeEvent<unknown>, 1)
    handleSearch(newValue, 1);
  };

  const loadPrograms = async (searchTerm: string, page: number) => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    const { data } = await apiClient.get(`{clientId}/programs?page=` + page + "&search=" + searchTerm);
    setPrograms(data);
    var mapped = data.data.map(mapData);
    setRows(mapped);
    setLoading(false);
  }

  useEffect(() => {

    // declare the data fetching function
    const fetchData = async () => {
      if (cookies.get("x-fathom-clientId") === undefined) {
        var loc = localStorage.getItem("saved_invite");
        if (loc != undefined) {
          navigate(loc || '')
        }
      } else {
        loadPrograms("", 1)
      }
    };
    setLoading(true);
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [editProgramId]);

  //   return programs.data.map((x: any) => (
  //     <Paper className="programOverview" sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} key={x.id}>
  //       <Container>
  //         <Grid container spacing={2} alignItems="center">
  //           <Grid sm={8} item >
  //             <Typography
  //               sx={{
  //                 marginTop: "10px",
  //               }}
  //               variant="h4"
  //             >
  //               {x.name}
  //             </Typography>
  //             <Typography sx={{
  //               marginBottom: "20px",
  //             }} variant="subtitle2">{x.description} <span className="bulletSpacer">•</span><span>{Moment(x.startDateUtc).format("Do MMMM YYYY")}</span></Typography>
  //           </Grid>
  //           <Grid sm={4} item textAlign="right">
  //             <Button variant="outlined" disableElevation size="small" hidden={!IsAdmin()} sx={{
  //               margin: "0 8px",
  //             }} onClick={() => edit(x.id)}>
  //               <BuildIcon fontSize="inherit" />&nbsp; Edit
  //             </Button>
  //             <Button variant="contained" disableElevation size="small" sx={{
  //               margin: "0 8px",
  //             }} onClick={() => viewReports(x.id)}>
  //               <BarChartIcon fontSize="inherit" />  &nbsp; Reports
  //             </Button>
  //           </Grid>
  //           <br />
  //           <br />
  //           <br />
  //           <br />
  //         </Grid>
  //       </Container>
  //     </Paper>
  //   ));
  // };

  const getPrograms = () => {
    return (
      <Grid container spacing={3}>
        {programs.data.map((x: any) => (
          <Grid item xs={12} sm={6} md={4} key={x.id}>
            <Tooltip title={x.description} placement="top">
              <Paper 
                className="programOverview" 
                sx={{ 
                  p: 2, 
                  display: "flex", 
                  flexDirection: "column", 
                  height: 128, 
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 3,
                  }
                }} 
                onClick={() => viewReports(x.id)}
              >
                <Typography variant="h5" noWrap sx={{ mb: 1, fontSize: '14px', fontWeight: 'bold' }}>
                  {x.name || "Untitled Program"}
                </Typography>

                <Stack 
                  direction="row" 
                  spacing={1} 
                  alignItems="center"
                  sx={{ 
                    flex: 1,
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <Avatar src={x.userPhoto} alt={x.userName} sx={{ width: 20, height: 20 }} />
                  <Typography variant="body2" sx={{ fontSize: '12px' , color: 'text.secondary' }} noWrap>{x.userName || "John Doe"}</Typography>
                </Stack>

                <Stack 
                  direction="row" 
                  spacing={3} 
                  alignItems="flex-start"
                  sx={{ 
                    pt: 2 
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <BarChartIconOutlined fontSize="small" sx={{ transform: 'rotate(90deg)' , color: 'text.secondary'}} />
                    <Typography variant="body2" sx={{ fontSize: '12px' , color: 'text.secondary'}}>{x.reportsCount || 3}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AutoFixHighIcon fontSize="small" sx={{ color: 'text.secondary'}} />
                    <Typography variant="body2" sx={{ fontSize: '12px' , color: 'text.secondary'}}>{x.insightsCount || 4}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <GroupIcon fontSize="small" sx={{ color: 'text.secondary'}} />
                    <Typography variant="body2" sx={{ fontSize: '12px' , color: 'text.secondary'}}>{x.engagementCount || 5}</Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Main>
      <Container>

          <br />
          <Button >Programs</Button>
          <Button variant="outlined" hidden={!IsAdmin()} onClick={() => createNew()} sx={{ float: 'right', marginBottom: '-30px' }}>
            New program
          </Button>
          <br />
          <br />
          {loading && programs.paging.length == 0 &&
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
              <LinearProgress color="secondary" />
              <br />
              <br />
              <br />
            </Paper>
          }


          {!loading &&
            <>
              <Stack spacing={2} direction="row" justifyContent="left" sx={{ marginBottom: '-20px' }}>
                <TextField
                  label="Search"
                  variant="standard"
                  value={search}
                  onChange={handleSearchChange}
                />
              </Stack>
              <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
                <Pagination
                  count={Math.ceil(programs.paging.total / programs.paging.pageSize)}
                  page={programs.paging.page}
                  onChange={handleChangePage}
                  color="primary"
                  variant="outlined"
                  showFirstButton showLastButton
                />
              </Stack>
            </>
          }
          {programs.paging.length == 0 && !loading && (
            <Paper>
              {" "}
              <Container>
                <br />
                <Typography variant="h6">
                  No programs found
                </Typography>
                <br />
              </Container>
            </Paper>
          )}
          {getPrograms()}
      </Container>
      <CreateProgram 
        drawerOpen={drawerOpen} 
        toggleDrawer={() => setDrawerOpen(!drawerOpen)} 
        editProgramId={editProgramId}
      />
      <br />
    </Main>
  );
}
