import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Box, Typography, Grid, LinearProgress, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import ViewInsightsCharts from "./view/insightsCharts";
import { InsightsResponse } from "components/insights/insightTypes";
import "./insights.css"
import LoadingChart from "./charts/loadingChart";

export default function ViewInsights({ programId }: any) {
    const { getAccessTokenSilently } = useAuth0();
    const [report, setReport] = useState(null as any as InsightsResponse);
    const [loading, setLoading] = useState(true);
    const [randomChartIndex, setRandomChartIndex] = useState(Math.floor(Math.random() * 2));

    const fetchData = async () => {
        setLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/insights/`);
        setReport(data);
        setLoading(false);
        if (data.status !== "Complete" && data.status.toLowerCase().indexOf("error") === -1) {
            setTimeout(async () => { await fetchData(), 3000 })
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData()
        .catch(console.error);

    }, [getAccessTokenSilently]);

    const syncing = () => {
        return report?.status !== "Complete";
    }

    const isError = (status: string) => {
        return (status || "").toLowerCase().indexOf("error") > -1;
    }

    return (
        <div>
            {!loading && !report && (
            <Container sx={{ marginBottom: "25px" }}>
               <Box 
                   sx={{ 
                       display: 'flex', 
                       flexDirection: 'column',
                       alignItems: 'center',
                       textAlign: 'center'
                   }}
               >
                   <br />
                   <Typography variant="h6">
                       Upload your first report on the right!
                   </Typography>
                   <br />
                   <LoadingChart index={randomChartIndex} />

               </Box>
            </Container>
            )}
            {report && syncing() &&
                <Grid item xs={12} sx={{
                    marginTop: "calc(5% + 60px)",
                    bottom: '50%',
                    width: "100%",
                }}>
                    <>
                        <Container>
                            {isError(report.status) &&
                                <>
                                    <br />
                                    <h4>We&apos;ve ran into an issue</h4>
                                    <Typography variant="subtitle2">Issue: {report.status}</Typography>
                                    <br />
                                    <br />
                                    <Typography variant="subtitle2">You can go back, click edit and upload your data again. <br /><br /> Quote report id : <b>{report.programId}</b> to the team if you need more help.</Typography>
                                    <br />
                                    <br />

                                </>
                            }
                            {!isError(report.status) && syncing() && 
                                <>
                                    <br />
                                    <br />
                                    <h4>Analysis in progress</h4>
                                    <LinearProgress color="secondary" />
                                    <br />
                                    <Typography variant="subtitle2">Current Status: {report.status}</Typography>
                                    <br />
                                    <Typography variant="subtitle2">This page will reload when analysis is complete</Typography>
                                    <br />
                                </>
                            }
                        </Container>
                    </>
                </Grid>
            }
            {!syncing() && <>
                <br />
                {loading &&
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                        <LinearProgress color="secondary" />
                        <br />
                        <br />
                        <br />
                    </Paper>
                } </>
            }
            {!syncing() && !loading &&
                <ViewInsightsCharts 
                programId={programId} 
                insightsCharts={report.insightsCharts} 
                fetchData={fetchData}/>
            }
        </div>
    )
}