import { Container, Typography, Select, SelectChangeEvent, MenuItem, Button, Grid, Box, Tooltip } from "@mui/material";
import { Survey } from "../types";
import { useState } from "react";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '56%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 900,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    "& > .MuiBackdrop-root": {
        bgcolor: 'white'
    }
};

type TypeMapperProps = {
    survey: Survey | undefined,
    updateQuestionType: (headerIndex: number, questionIndex: number, type: string) => void,
    triggerAnalysis: () => void,
    reportId: string
};

const isNumber = (value?: string): boolean => {
    return ((value != null) &&
        !isNaN(Number(value.toString())));
}

const TypeMapper = ({ survey, updateQuestionType, triggerAnalysis, reportId }: TypeMapperProps) => {
    const [isClicked, setIsClicked] = useState(false);
    const getQuestions = () => {
        return (
            <Grid container spacing={2} >
                {survey?.structure?.map((qh, i) => {
                    return (<Container className="questionBox" key={i} sx={{ padding: 2 }}>
                        <Grid item xs={12}><b>Question {i + 1}</b> <br /> {qh.questionHeader}</Grid>
                        {qh.questions.map((q, y) => {
                            return (
                                <Box key={y} sx={{ mt: "20px" }}>
                                    <Container >
                                        <Grid item xs={12} className="questionBox" sx={{ padding: 2 }}>{q.title}</Grid>
                                        <Grid item xs={12} className="questionType">
                                            <Select
                                                sx={{ width: "100%" }}
                                                labelId="role-select-label"
                                                id="role-select"
                                                value={q.type}
                                                label="Role"
                                                onChange={(mode: SelectChangeEvent) => {
                                                    updateQuestionType(i, y, mode.target.value)
                                                }}

                                            >
                                                <MenuItem value={"Multi"}>Multi-choice</MenuItem>
                                                <MenuItem disabled={q.responses.some((x) => !isNumber(x.response))} value={"Rating"}>Rating</MenuItem>
                                                <MenuItem value={"Open"}>Open text</MenuItem>
                                                <MenuItem value={"Opinion"}>Opinion scale</MenuItem>
                                                <MenuItem value={"Nps"}>Nps</MenuItem>
                                                <MenuItem value={"Ranking"}>Ranking</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Container>
                                </Box>
                            )
                        })}</Container>)
                })} <br />
            </Grid>
        )
    }

    return (
        <Container>
            <br />
            <Grid container spacing={2} >
                <Grid item xs={8}>
                    <Typography id="modal-modal-title" variant="h6" component="h3">
                        Confirm type mappings
                    </Typography>
                    <Typography variant="subtitle2">
                        We&apos;ve mapped these questions to types but please confirm
                    </Typography>

                </Grid>
                <Grid item xs={4}>
                    <div style={{ display: "flex", float: 'right' }}>
                        <Tooltip title={(survey?.title === undefined || survey?.title === "") ? "Please enter a report title" : ""}>
                            <span>
                                <Button style={{ marginLeft: "auto" }} disabled={isClicked || survey?.title === undefined || survey?.title === ""} onClick={() => {
                                    setIsClicked(true);
                                    triggerAnalysis();
                                    setIsClicked(false);
                                }} variant="outlined">
                                    Start Analysis
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <br />
            {getQuestions()}

        </Container>
    )
}

export default TypeMapper;