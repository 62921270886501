import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Box, DialogTitle, Typography, IconButton, Grid, TextField, CircularProgress, Alert, List, Tooltip, Checkbox, Button, Chip, FormControl, Input, InputLabel, MenuItem, Select, FormLabel, RadioGroup, FormControlLabel, SelectChangeEvent, Container, Rating, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FathomClient from "@api/fathomapi";
import { ChartDataPoint, InsightsChartType, ProgramReportQuestions, ReportQuestion, ReportQuestionId } from "components/insights/insightTypes";
import NpsInsightsChart from "components/insights/charts/npsInsightsChart";
import OpinionInsightsChart from "components/insights/charts/opinionInsightsChart";
import RatingInsightsChart from "components/insights/charts/ratingInsightsChart";
import LoadingChart from "../charts/loadingChart";
import OpinionSummaryInsightsChart from "components/insights/charts/opinionSummaryInsightsChart";
import { enqueueSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const env = process.env.REACT_APP_ENV;

const allowChartTypes = {
    [InsightsChartType.OpinionSummary]: [InsightsChartType.Opinion],
    [InsightsChartType.Opinion]: [InsightsChartType.Opinion, InsightsChartType.OpinionSummary],
    [InsightsChartType.Rating]: [InsightsChartType.Rating],
    [InsightsChartType.Nps]: [InsightsChartType.Nps],
    [InsightsChartType.Open]: [] as InsightsChartType[],
    [InsightsChartType.Multi]: [] as InsightsChartType[],
    [InsightsChartType.Ranking]: [] as InsightsChartType[],
    [InsightsChartType.Unmapped]: [] as InsightsChartType[],
}


export default function AddInsightChart({ programId, open, setModalOpen, order, fetchData }: any) {

    const [chartDataPoint, setChartDataPoint] = useState({
        title: "",
        order: order,
        filter: {
            reportIdFilter: [],
            questionIdFilter: [],
            type: InsightsChartType.Rating,
            gptquery: ""
        },
        id: undefined
    } as any as ChartDataPoint);

    const [randomChartIndex, setRandomChartIndex] = useState(Math.floor(Math.random() * 2));

    const [optionsLoading, setOptionsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [allowQuery, setAllowQuery] = useState(true);

    const [chartQueryData, setChartQueryData] = useState({
        categories: [],
        series: new Map<string, number[]>()
    } as any);

    const [reportQuestionMap, setReportQuestionMap] = useState(new Map<string, ReportQuestionId[]>());

    const [chartOptions, setChartOptions] = useState({
        programId: programId,
        reports: [],
    } as ProgramReportQuestions);

    const [validChartOptions, setValidChartOptions] = useState({
        programId: programId,
        reports: [],
    } as ProgramReportQuestions);

    const { getAccessTokenSilently } = useAuth0();
    const canSave = () => {
        return chartDataPoint.title !== "" && chartDataPoint.filter.reportIdFilter.length > 0;
    }
    const getOptions = async () => {
        setOptionsLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const response = await apiClient.get(`{clientId}/programs/${programId}/insights/options`);
        setChartOptions(response.data);

        let validReports = (response.data as ProgramReportQuestions).reports.filter(y => y.questionIds.map(z => z.type == chartDataPoint.filter.type).length > 0);
        setValidChartOptions({ ...validChartOptions, reports: validReports })
        setOptionsLoading(false);
    }

    const getData = async () => {
        setDataLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        if (chartDataPoint.filter.reportIdFilter.map((y: any) => y.reportId).length > 0 && chartDataPoint.filter.questionIdFilter.map((y: any) => y.questionId).length > 0) {
            var filter = {
                reportIdFilter: chartDataPoint.filter.reportIdFilter.map((y: any) => y.reportId),
                questionIdFilter: chartDataPoint.filter.questionIdFilter.map((y: any) => y.questionId),
                type: chartDataPoint.filter.type,
                gptquery: chartDataPoint.filter.gptquery
            }
            setAllowQuery(true);
            const response = await apiClient.post(`{clientId}/programs/${programId}/insights/data`, filter)
            .catch(err =>{
                enqueueSnackbar(err, {
                    variant: "error",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    }
                  });
                return;
            });
            if(response != null){
                setChartQueryData({
                    data: response?.data
                });
            }
        }
        setDataLoading(false);
    }

    useEffect(() => {
        getOptions();
    }, [order, open])

    useEffect(() => {
        if (chartDataPoint.filter.gptquery.length == 0 
            && chartDataPoint.filter.reportIdFilter.length > 0 
            && chartDataPoint.filter.reportIdFilter[0] != null) {
            getData();
        }
    }, [chartDataPoint.filter.reportIdFilter, chartDataPoint.filter.questionIdFilter])

    const clearModal = () => {
        setModalOpen(false);
        setChartOptions({
            programId: programId,
            reports: []
        })
        setValidChartOptions({
            programId: programId,
            reports: []
        })
        setChartQueryData({
            categories: [],
            series: new Map<string, number[]>()
        })

        setChartDataPoint({
            title: "",
            filter: {
                reportIdFilter: [],
                questionIdFilter: [],
                type: InsightsChartType.Rating,
                gptquery: "",
            },
            id: undefined
        })

    };

    const handleReportChange = (event: any) => {
        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                reportIdFilter: event.target.value
            }
        })

    };

    const handleQuerySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setChartDataPoint({ ...chartDataPoint, filter: { ...chartDataPoint.filter, gptquery: newValue } })
    };

    const handleQuestionChange = (report: any, event: any) => {


        let mp = reportQuestionMap;

        if (event.target.value === "clear") {
            mp.delete(report.reportId);
        } else {
            mp.set(report.reportId, event.target.value)
        }

        let allQuestions = Array.from(mp.values()).flat()
        setReportQuestionMap(mp);

        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                questionIdFilter: allQuestions
            }
        })
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        let newType = event.target.value as unknown as InsightsChartType

        setChartDataPoint({
            ...chartDataPoint,
            filter: {
                reportIdFilter: [],
                questionIdFilter: [],
                type: newType,
                gptquery: "",
            }
        })

        let mp = reportQuestionMap;
        mp.clear();

        setReportQuestionMap(mp);

        let validReports = chartOptions.reports.filter(y => y.questionIds.map(z => allowChartTypes[newType].indexOf(z.type) > -1).length > 0);
        setValidChartOptions({ ...validChartOptions, reports: validReports })

    };

    const confirmData = async () => {
        setOptionsLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        var filter = {
            reportIdFilter: chartDataPoint.filter.reportIdFilter.map((y: any) => y.reportId),
            questionIdFilter: chartDataPoint.filter.questionIdFilter.map((y: any) => y.questionId),
            type: chartDataPoint.filter.type,
            gptquery: chartDataPoint.filter.gptquery
        }

        chartDataPoint.filter = filter;

        await apiClient.post(`{clientId}/programs/${programId}/insights/chart`, chartDataPoint);
        setOptionsLoading(false);
        setTimeout(fetchData(programId), 3000);
        clearModal();

    }

    return (
        <Modal open={open} onClose={clearModal}>
            <Box className="modal-content">
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography variant="h6" component="h2">Create Insight</Typography>
                            <Typography variant="subtitle1">Select questions from the reports below to compare</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={clearModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Box className="modal-body">
                    {!optionsLoading &&
                        <Grid container spacing={2}>
                            <Grid item container xs={6}   >
                                <div className="rounded-box top-box">
                                    <Grid container item xs={12} >
                                        <Grid item xs={3} ><span className="titleFieldRow">Title</span></Grid>
                                        <Grid item xs={9} >
                                            <FormControl fullWidth className="dataFilterField">
                                                <TextField
                                                    fullWidth
                                                    value={chartDataPoint.title}
                                                    onChange={(e) => setChartDataPoint({ ...chartDataPoint, title: e.target.value })}
                                                    margin="normal"
                                                />
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                    <Grid item container xs={12} >

                                        <Grid item xs={3} ><span className="titleFieldRow">Chart type</span></Grid>
                                        <Grid item xs={9} >
                                            <FormControl fullWidth className="dataFilterField">
                                                <Select
                                                    labelId="type-filter"
                                                    fullWidth
                                                    className="selectBox"
                                                    id="type-filter-chip"
                                                    value={chartDataPoint?.filter?.type}
                                                    onChange={handleTypeChange}
                                                    input={<Input id="select-multiple-chip" />}
                                                    disableUnderline
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value={InsightsChartType.Nps}>Nps</MenuItem>
                                                    <MenuItem value={InsightsChartType.Rating}>Rating</MenuItem>
                                                    <MenuItem value={InsightsChartType.Opinion}>Opinion</MenuItem>
                                                    <MenuItem value={InsightsChartType.OpinionSummary}>Opinion Summary</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item container xs={12}>
                                        <Grid item xs={3} ><span className="titleFieldRow">Reports</span></Grid>
                                        <Grid item xs={9} >
                                            <FormControl fullWidth className="dataFilterField">
                                                <Select
                                                    labelId="question-filter"
                                                    fullWidth
                                                    className="selectBox"
                                                    id="question-filter-chip"
                                                    multiple
                                                    value={chartDataPoint?.filter?.reportIdFilter}
                                                    onChange={handleReportChange}
                                                    input={<Input id="select-multiple-chip" />}
                                                    MenuProps={MenuProps}
                                                    disableUnderline
                                                    renderValue={(selected) => (<>{selected.length > 1 ? selected.length + " selected" : selected[0].title}</>)}
                                                >
                                                    {validChartOptions.reports.map((report) => (
                                                        <MenuItem value={report as any} key={report.reportId}>
                                                            <Tooltip title={report.title.length > 45 ? report.title : ''}  >
                                                                <span>{report.title}</span>
                                                            </Tooltip>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid item xs={12} className={chartDataPoint?.filter?.reportIdFilter.length > 0 ? "rounded-box middle-box" : ""}>
                                    {chartDataPoint?.filter?.reportIdFilter.map((report: ReportQuestion) =>
                                        <Grid container item xs={12} key={report.reportId} >
                                            <Grid item xs={3} ><span className="titleFieldRow">{report.title}</span></Grid>
                                            <Grid item xs={9} >
                                                <Select
                                                    labelId="question-filter"
                                                    className="selectBox"
                                                    id="question-filter-chip"
                                                    value={reportQuestionMap.get(report.reportId) || []}
                                                    multiple
                                                    onChange={(event) => handleQuestionChange(report, event)}
                                                    input={<Input id="select-multiple-chip" />}
                                                    MenuProps={MenuProps}
                                                    disableUnderline
                                                    renderValue={(selected) => (<>{selected.length > 1 ? selected.length + " selected" : selected[0].title}</>)}
                                                    fullWidth
                                                >

                                                    {report.questionIds
                                                        .filter((y) => allowChartTypes[chartDataPoint?.filter?.type].indexOf(y.type) > -1)
                                                        .map((question: any) => (
                                                            <MenuItem value={question as any} key={question.questionId}>
                                                                <Tooltip title={question.title.length > 45 ? question.title : ''}  >
                                                                    <span>{question.title}</span>
                                                                </Tooltip>
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <div className="rounded-box bottom-box" hidden={env == "Prod"} >
                                    <Grid item xs={12}>
                                        <Grid container item xs={12} >
                                            <Grid item xs={3} ><span className="titleFieldRow">Query</span></Grid>
                                            <Grid item xs={9} >
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    maxRows={8}
                                                    minRows={4}
                                                    disabled={!allowQuery}
                                                    value={chartDataPoint.filter.gptquery}
                                                    onChange={handleQuerySearchChange}
                                                />
                                                </Grid>
                                            <Button hidden={chartDataPoint.filter.gptquery.length == 0 } disabled={dataLoading} onClick={getData} >Refresh</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                {chartDataPoint?.filter?.reportIdFilter.length == 0 && <Grid item xs={12} ></Grid>}

                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    <div className="rounded-box chartpreview-box">
                                        <Typography variant="subtitle1">Preview</Typography>
                                        <br />
                                        <div className="rounded-box">
                                            {
                                                chartQueryData?.data?.categories.length > 0 && chartDataPoint?.filter?.questionIdFilter.length > 0 &&
                                                <>
                                                    <Typography variant="h6" component="h2">&nbsp;&nbsp;&nbsp;{chartDataPoint.title}</Typography>   <br />
                                                    {
                                                        chartDataPoint.filter.type == InsightsChartType.Nps &&
                                                        <NpsInsightsChart insight={chartQueryData} />
                                                    }
                                                    {
                                                        chartDataPoint.filter.type == InsightsChartType.Opinion &&
                                                        <OpinionInsightsChart insight={chartQueryData} />
                                                    }
                                                    {
                                                        chartDataPoint.filter.type == InsightsChartType.Rating &&
                                                        <RatingInsightsChart insight={chartQueryData} />
                                                    }
                                                    {
                                                        chartDataPoint.filter.type == InsightsChartType.OpinionSummary &&
                                                        <OpinionSummaryInsightsChart insight={chartQueryData} />
                                                    }

                                                </>
                                            }
                                            {
                                                (chartQueryData?.data?.categories.length == 0 || chartDataPoint?.filter?.questionIdFilter.length == 0) &&
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <LoadingChart title="Data insight" index={randomChartIndex} />

                                                </Box>
                                            }
                                            {
                                                dataLoading && <LinearProgress color="secondary" />
                                            }

                                        </div>
                                    </div>
                                </Container>
                            </Grid>
                        </Grid>

                    }
                </Box>
                <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Grid item xs={1}>
                        <Tooltip title={canSave() ? '' : "Please set a title and select at least one report"}>
                            <span><Button variant="outlined" disabled={!canSave()} onClick={() => confirmData()}>Confirm </Button></span>
                        </Tooltip>
                    </Grid>

                </Box>
            </Box >
        </Modal >
    );
}