import { Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function MetricsChart({ env, rating, chartComponentRef, green }: any) {

    if (env == "Prod" || rating.metricScore == undefined) {
        return (<></>)
    }

    return (<Grid container spacing={4} sx={{ marginBottom: "15px", marginTop: "10px" }}>
        <Grid xs={3} >
            <HighchartsReact
                highcharts={Highcharts}
                options={getMetricCircleOptions(rating.metricScore.level1, "Reaction", green)}
                ref={chartComponentRef}
                containerProps={{ style: { height: "100%" } }}
            />
        </Grid>
        <Grid xs={3} >
            <HighchartsReact
                highcharts={Highcharts}
                options={getMetricCircleOptions(rating.metricScore.level2, "Learning", green)}
                ref={chartComponentRef}
            />
        </Grid>
        <Grid xs={3}>
            <HighchartsReact
                highcharts={Highcharts}
                options={getMetricCircleOptions(rating.metricScore.level3, "Behavior", green)}
                ref={chartComponentRef}
            />
        </Grid>
        <Grid xs={3} >
            <HighchartsReact
                highcharts={Highcharts}
                options={getMetricCircleOptions(rating.metricScore.level4, "Results", green)}
                ref={chartComponentRef}
            />
        </Grid>
    </Grid>)
}


function getMetricCircleOptions(score: any, title: string, green: boolean = true): Highcharts.Options {
    return {
        chart: {
            renderTo: 'container',
            type: 'pie',
            backgroundColor: 'rgba(0,0,0,0.0)',
            spacingTop: 30,
            spacingRight: 0,
            spacingBottom: 30,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, 0, 0, 0],
            height: 90,
        },
        title: {
            verticalAlign: 'middle',
            floating: true,
            text: score + '%',
            style: {
                fontSize: '15px'
            }
        },
        subtitle: {
            text: title,
            align: 'center',
            verticalAlign: 'bottom',
            y: 40,
            style: {
                fontSize: '15px'
            }
        },
        xAxis: {
            gridLineColor: 'transparent',
            gridLineWidth: 0,
            lineColor: 'transparent',
            lineWidth: 0,
            labels: {
                enabled: false
            },
            tickWidth: 0,
            minPadding: 0,
            maxPadding: 0
        },
        yAxis: {
            title: {
                text: null
            }
        },
        plotOptions: {
            pie: {
                innerSize: '100%',
            },
            series: {
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        series: [{
            name: 'Score',
            data: [{
                y: score,
                name: "Online",
                color: green ? 'rgb(69, 194, 126)' : 'rgb(99, 39, 230)',
            },
            {
                y: 100 - score,
                name: "Online",
                color: 'rgba(0,0,0,0.1)'
            }
            ],
            showInLegend: false,
            dataLabels: {
                enabled: false
            },
            type: "pie",
            size: '70%',
            innerSize: '80%',
        }],
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    };
}