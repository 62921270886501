import { Container, Grid, LinearProgress, Paper, Toolbar, Typography } from "@mui/material";
import { Recommendation } from "../../report";
import { Marked } from '@ts-stack/markdown';
import "./reportview.css";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PollIcon from '@mui/icons-material/Poll';


export default function OutcomeDetailTab({ outcome }: any) {

    const getContent = (content: string) => {
        content = content == null ? "" : content;
        content = content.replaceAll("[doc1]", "").replaceAll("[doc2]", "").replaceAll("[doc3]", "").replaceAll("[doc4]", "").replaceAll("[doc5]", "");
        content = Marked.parse(content);
        content = content.replaceAll("h3", "h6",)
        content = content.replaceAll("h4", "h6")
        content = content.replaceAll("<h6 ", "<h6 class=\"moduleStrongText\"")
        content = content.replaceAll("<strong>", "")
        content = content.replaceAll("</strong>", "")
        content = content.replaceAll("<ul>", "<blockquote>")
        content = content.replaceAll("</ul>", "</blockquote>")
        content = content.replaceAll("<li>", "<p>")
        content = content.replaceAll("</li>", "</p>")
        content = content.replaceAll("</p>", "</div>")
        content = content.replaceAll("\\u0027", "'")        
        content = content.replaceAll("<p>", "<div class=\"moduleSummaryText\">")
        content = content.replaceAll("<blockquote>", "<blockquote class=\"moduleSummaryTextQuote\">")

        return content;
    }

    return (
        <>
            <br />
            <br />
            <br />
            <Grid container spacing={4}>

                <Grid container spacing={4}>
                    <br />
                    <Grid item xs={12}>


                        <Paper id="reportTitle" className='reportTile'>
                            <Typography variant="subtitle1"><PollIcon sx={{ color: '#6327E6', marginRight: '10px', marginTop: "-2px" }} />Summary</Typography>
                            <Typography variant="subtitle2" sx={{ color: '#6B6876' }}>  {outcome.summary}</Typography>
                            <br />
                            {outcome.detailedSummary}</Paper>
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Paper>
                            <br />
                            <Container>
                                <Typography variant="subtitle1"><QuestionAnswerIcon sx={{ color: '#45C27E', marginRight: '10px' }} />Key takeaways</Typography>
                                <Typography variant="subtitle2" sx={{ color: '#6B6876' }}>Summary</Typography>
                                <br />
                                {outcome.recommendations.map((recc: Recommendation) => {
                                    return (
                                        <Paper sx={{ marginBottom: "10px", borderShadow: "0px", borderRadius: "10px" }} key={recc.title}>
                                            <Container>
                                                <Typography variant="subtitle2" sx={{ marginTop: "10px", marginBottom: "10px" }}> {recc.fullRecommendation} </Typography>
                                            </Container>

                                        </Paper>
                                    )
                                })}
                                <br />
                            </Container>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper>
                            <br />
                            <Container>
                                <Typography variant="subtitle1"><QuestionAnswerIcon sx={{ color: '#6327E6', marginRight: '10px' }} />Full Overview</Typography>
                                <Typography variant="subtitle2" sx={{ color: '#6B6876' }}>Summary</Typography>
                                <br />
                                <div dangerouslySetInnerHTML={{ __html: getContent(outcome.summaryBreakdown) }}></div>
                                <br />
                            </Container>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}